






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";
import moment from "moment";
import Values from "./partials/Values.vue";
import UsersVue from "./teams/partials/Users.vue";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      month: parseInt(this.$route.params.month, 10),
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "departmentId",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          displayFields: {
            stt: {
              text: "STT",
              options: {
                index: true,
              },
            },
            id: {
              text: "ID",
              hidden: true,
            },
            department: {
              text: "Phòng",
              options: {
                subProp: "department.name",
                label: true,
              },
            },
            team: {
              text: "Team",
              options: {
                subProp: "team.name",
                label: true,
                sortBy: "teamId",
              },
            },
            startDurationInMonth: {
              text: "Số tháng hoạt động",
              sortable: true,
            },
            teamLevel: {
              text: "Cấp độ team",
              sortable: true,
            },
            totalUser: {
              text: "SL thành viên",
              sortable: true,
            },
            totalCtUser: {
              text: "SL chính thức",
              sortable: true,
            },
            totalTvUser: {
              text: "SL thử việc",
              sortable: true,
            },
            totalCtNvUser: {
              text: "SL chính thức nghỉ việc",
              sortable: true,
            },
            totalTvNvUser: {
              text: "SL thử việc nghỉ việc",
              sortable: true,
            },
            total2ndMonthTvUser: {
              text: "SL thử việc làm việc tháng thứ 2",
              sortable: true,
            },
            kpi: {
              text: "KPI",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            committedRevenue: {
              text: "Doanh số",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            committedCpqc: {
              text: "CPQC",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            qualityRevenue: {
              text: "Doanh số hiệu quả",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            minimumQualityRevenue: {
              text: "Doanh số hiệu quả tối thiểu",
              sortable: true,
              options: {
                filter: "currency",
              },
            },
            qualityRevenuePerTotalUser: {
              text: "Doanh số hiệu quả / Số người chốt KPI",
              sortable: true,
              options: {
                preTransform: (_, item) => {
                  return item.totalUser ? (item.qualityRevenue || 0) / item.totalUser : 0;
                },
                filter: "currency",
              },
            },
            qualityRevenuePassed: {
              text: "Hiệu quả làm việc",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
              hidden: true,
            },
            action: {},
          },
          topActionButtons: {
            calculate: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Tính toán",
                icon: "mdi-calculator",
              },
              target: {
                dialog: {
                  content: {
                    toolbar: {
                      title: "Tính toán",
                    },
                    content: {
                      text: {
                        text: "Tính toán",
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Submit",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const { month } = dataTable.context();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call("salaryTeamMonthInfos", "calculate", {
                                  month: moment(month).startOf("month").valueOf(),
                                });
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("salaryTeamMonthInfos", `@/export`, findAllOptions);
                },
              },
            },
            import: userManager.checkRole(["kt"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Import",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Import" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Import",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const { month } = dataTable.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await coreApiClient.call(
                              "salaryTeamMonthInfos",
                              "import",
                              {
                                month: moment(month).startOf("month").valueOf(),
                              },
                              data
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                              dataTable.refresh();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              values: {
                content: {
                  icon: "mdi-view-list-outline",
                },
                target: {
                  tooltip: { content: { text: "Tham số tính toán" } },
                  dialog: {
                    attrs: {
                      width: "900px",
                    },
                    content: {
                      autofocus: false,
                      toolbar: {
                        title: "Tham số tính toán",
                        subTitle(self) {
                          const btn = self.context();
                          const { item } = btn.context();
                          return item.team.name;
                        },
                      },
                      content: {
                        type: Values,
                        attrs: {},
                        makeAttrs(attrs: any = {}, dialog) {
                          const { item } = dialog.context().context();
                          console.log(item);
                          attrs.item = item;
                          return attrs;
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Đóng",
                          },
                        },
                      },
                    },
                  },
                },
              },
              users: {
                content: {
                  icon: "mdi-account-group",
                },
                target: {
                  tooltip: { content: { text: "Users" } },
                  dialog: {
                    content: {
                      autofocus: false,
                      toolbar: {
                        title: "Users",
                        subTitle(self) {
                          const btn = self.context();
                          const { item } = btn.context();
                          return item.team.name;
                        },
                      },
                      content: {
                        type: UsersVue,
                        attrs: {},
                        makeAttrs(attrs: any = {}, dialog) {
                          const { item } = dialog.context().context();
                          console.log(item);
                          attrs.item = item;
                          return attrs;
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Đóng",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            const { month } = this.context();
            options.filters.push({
              key: "month",
              operator: "equal_to",
              value: moment(month).startOf("month").valueOf(),
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("salaryTeamMonthInfos", "findAll", options);
              return [items, count];
            },
          },
        },
      },
      tableContainer: new DataContainer(),
    };
  },
});
